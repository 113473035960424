import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue';
import { priceRuleService, dictionaryHelper, productService } from '@/main';
import to from 'await-to-js';
import PriceRule from '../../models/PriceRule';
import Product from '../../models/Product';
import Site from '../../models/site';
import { required } from 'vuelidate/lib/validators';
import { IVuelidate, validationMixin } from 'vuelidate';
import BasePage from '@/models/BasePage';
import PriceRuleGroup from '@/models/PriceRuleGroup';
import PriceRuleCondition from '@/models/PriceRuleCondition';
import { sitesModule } from '@/store/modules/site';

@Component({
    mixins: [validationMixin],
    components: {},
    validations: {
        selectedSites: { required },
        priceRule: {
            name: { required },
            priceRuleGroups: {
                required,
                $each: {
                    priceRuleConditions: { required },
                },
            },
            product: { required },
        },
    },
})
export default class PriceRulePage extends BasePage implements IVuelidate<any> {
    public isLoading: boolean = true;
    public isLoadingPriceRuleTypes: boolean = true;
    public editMode: boolean = true;
    public priceRuleKey: string = '';
    public priceRule: PriceRule = {} as PriceRule;
    public priceRuleTypes: any = [];
    public selectedSites: number[] = [];
    public sites: Site[] = [];

    public selectedPriceRuleGroupIndex: number = 0;
    public selectedPriceRuleGroup: PriceRuleGroup = { name: '', priceRuleConditions: [], editMode: true };
    public selectedPriceRuleType: any = {};
    public conditionTypeComponentName: string = '';
    public selectedCondition: PriceRuleCondition = { type: '', conditionGroup: '', shouldMatch: true };
    public dictionary = dictionaryHelper;
    public products: any[] = null;
    public forceRender: number = 0;

    public $refs: {
        conditionModal: BModal;
    };

    public async created() {
        this.priceRuleKey = this.$route.params.priceRuleKey;
        this.priceRule = await this.getPriceRule(this.priceRuleKey);
        this.selectedSites = this.priceRule.siteIds;
        await this.loadProducts();
        await sitesModule.fetchSitesIfNeeded();
        this.sites = sitesModule.sites;

        this.isLoading = false;
        if (this.priceRule.priceRuleGroups.length) {
            this.setGroup(0);
        }
    }

    public async loadProducts() {
        const [err, response] = await to(productService.getProducts());
        if (err) {
            this.showError('Producten en kortingen ophalen mislukt');
        }
        return (this.products = response.data);
    }

    public async getPriceRule(priceRuleKey) {
        const newPriceRule = {
            isActive: true,
            product: null,
            productId: null,
            priceRuleGroups: [],
            name: '',
            siteIds: [],
        } as PriceRule;

        if (priceRuleKey === 'nieuw') {
            return newPriceRule;
        }

        const [err, response] = await to(priceRuleService.getPriceRule(priceRuleKey));
        if (err) {
            this.showError('Ophalen van business regel mislukt.');
            return newPriceRule;
        }

        return response.data;
    }

    public getPriceRuleTypeIcon(type) {
        if (!type) {
            return '';
        }

        const name = type.toLowerCase();
        if (name.indexOf('date') > -1 || name.indexOf('day') > -1) {
            return 'calendar';
        }

        if (name.indexOf('accommodation') > -1 || name.indexOf('place') > -1) {
            return 'home';
        }

        if (name.indexOf('person') > -1 || name.indexOf('traveller') > -1 || name.indexOf('visitor') > -1 || name.indexOf('owner') > -1) {
            return 'user1';
        }

        if (name.indexOf('code') > -1) {
            return 'dice';
        }
    }

    public setGroup(index) {
        this.selectedPriceRuleGroupIndex = index;
        this.selectedPriceRuleGroup = this.priceRule.priceRuleGroups[index];
    }

    public getPriceRuleConditions() {
        const index = this.selectedPriceRuleGroupIndex ? this.selectedPriceRuleGroupIndex : 0;
        if (this.priceRule && this.priceRule.priceRuleGroups && this.priceRule.priceRuleGroups[index]) {
            return this.priceRule.priceRuleGroups[index].priceRuleConditions;
        }
        return [];
    }

    public getSelectedGroup() {
        return this.selectedPriceRuleGroup;
    }

    public getConditionName(id: number) {
        const type = this.priceRuleTypes.find((t) => t.priceRuleConditionTypeId === id);
        return type ? type.name : 'UnknownType';
    }

    public async createCondition() {
        this.selectedCondition = { type: '' } as PriceRuleCondition;
        this.$refs.conditionModal.show();

        await this.getPriceRuleTypes();
        // select first price rule type as condition type
        this.selectConditionType(this.priceRuleTypes[0]);
    }

    public async editCondition(condition) {
        this.selectedCondition = condition;
        this.selectConditionType(condition.type);

        this.$refs.conditionModal.show();
        await this.getPriceRuleTypes();
    }

    public async getPriceRuleTypes() {
        if (!this.priceRuleTypes.length) {
            this.isLoadingPriceRuleTypes = true;
            this.priceRuleTypes = priceRuleService.getPriceRuleTypes();
            this.isLoadingPriceRuleTypes = false;
        }
    }

    public saveCondition() {
        const index = this.priceRule.priceRuleGroups[this.selectedPriceRuleGroupIndex].priceRuleConditions.indexOf(this.selectedCondition);
        if (index > -1) {
            this.removeConditionFromGroup(index);
        }

        this.addConditionToGroup(this.selectedCondition);
        this.selectedCondition = {} as PriceRuleCondition;

        this.$refs.conditionModal.hide();
        this.forceRender++;
    }

    public removeCondition(index) {
        this.removeConditionFromGroup(index);
    }

    public selectConditionType(type) {
        this.selectedCondition.type = type;
        this.conditionTypeComponentName = 'price-rule-' + type.toLowerCase();
    }

    public addGroup() {
        this.priceRule.priceRuleGroups.push({
            name: 'Group',
            priceRuleConditions: [],
            editMode: true,
        });
    }

    public addConditionToGroup(condition) {
        const index = this.selectedPriceRuleGroupIndex;
        this.priceRule.priceRuleGroups[index].priceRuleConditions.push(condition);
    }

    public removeConditionFromGroup(index) {
        const groupIndex = this.selectedPriceRuleGroupIndex;
        this.priceRule.priceRuleGroups[groupIndex].priceRuleConditions.splice(index, 1);
    }

    public removeGroup(index) {
        if (index === 0) {
            return;
        }

        this.priceRule.priceRuleGroups.splice(index, 1);
        this.setGroup(0);
    }

    public async save() {
        if (!this.validateObject('selectedSites') || !this.validateObject('priceRule')) {
            return this.clearAndShowError('Niet alle verplichte velden zijn ingevuld.');
        }

        if (!this.priceRule.name || this.priceRule.name === '') {
            return;
        }

        this.showPending('Business regel wordt opgeslagen...');

        if (this.priceRule.product) {
            this.priceRule.productId = this.priceRule.product.productId;
            delete this.priceRule.product; // remove this or else EF wants to save this also
        }

        this.priceRule.siteIds = this.selectedSites;
        const [err, response] = await to(priceRuleService.storePriceRule(this.priceRule));

        if (err) {
            this.clearAndShowError('Mislukt om business regel op te slaan.', err);
            this.priceRule.product = this.products.find((x) => x.productId === this.priceRule.productId);
        } else {
            this.clearAndShowSuccess('Business regel succesvol opgeslagen.');
            this.editMode = false;
            this.$router.push({ name: 'price-rules' });
        }
    }

    public selectProduct(id: number) {
        const selectedProduct = this.products.find((product) => {
            return product.productId === id;
        }) as Product;

        this.priceRule.product = selectedProduct;
        this.priceRule.productId = selectedProduct.productId;
    }

    public removeProduct() {
        this.priceRule.product = null;
        this.priceRule.productId = null;
    }

    public edit() {
        this.editMode = true;
    }

    public cancelEdit() {
        this.editMode = false;
    }
}
