import { productService, dictionaryHelper, bookingService, financeAccountService } from '@/main';
import Component from 'vue-class-component';
import to from 'await-to-js';
import Product from '../../models/Product';
import PriceRule from '../../models/PriceRule';
import { $router } from '@/router';
import BasePage from '@/models/BasePage';
import { required } from 'vuelidate/lib/validators';
import { IVuelidate, validationMixin } from 'vuelidate';
import { Watch } from 'vue-property-decorator';
import { ProductTypeEnum } from '@/models/ProductTypeEnum';
import { sitesModule } from '@/store/modules/site';
import Site from '@/models/site';

const mustHaveType = (value) => value !== ProductTypeEnum.Unknown && value !== '';

@Component({
    mixins: [validationMixin],
    components: {},
    validations: {
        selectedSites: { required },
        product: {
            description: { required },
            productType: { mustHaveType },
            vatId: { required },
            financeAccount: { financeAccountId: { required } },
        },
    },
})
export default class ProductComponent extends BasePage implements IVuelidate<any> {
    public product: Product = {} as Product;
    public isLoading: boolean = true;
    public editMode: boolean = false;
    public productTypes: any[] = null;
    public dictionary = dictionaryHelper;
    public priceRules: PriceRule[] = [];
    public VATs: any[] = [];
    public travelTypes: any[];
    public financeAccounts: any[] = [];
    public selectedSites: number[] = [];
    public oldSelectedSites: number[] = null;
    public productTypeIdObject: any = null;

    @Watch('productTypeIdObject')
    public onProductTypeIdObjectChange(val, oldVal) {
        if (val) {
            this.product.productTypeId = val.id;
            this.product.productType = val.name;
        }
    }

    @Watch('product.vat')
    public onVatObjectChange(val, oldVal) {
        if (val) {
            this.product.vatId = val.vatId;
            this.product.vat = val;
        }
    }

    @Watch('product.financeAccount')
    public onFinAccObjectChange(val, oldVal) {
        if (val) {
            this.product.financeAccountId = val.financeAccountId;
        }
    }

    public async created() {
        await Promise.all([
            sitesModule.fetchSitesIfNeeded(),
            this.getTravelTypes(),
            this.loadVATs(),
            this.loadProductTypes(),
            this.loadFinanceAccounts(),
        ]);
        this.product = await this.getProduct();
        this.oldSelectedSites = this.product.siteIds;

        if (this.product.productId) {
            await this.loadRelatedPriceRules(this.product.productId);
        }

        this.isLoading = false;
    }

    public get sites() {
        return sitesModule.sites;
    }

    public get siteOptions() {
        return this.sites.map((site: Site) => {
            return {
                value: site.siteId,
                text: site.name,
            };
        });
    }

    public async getTravelTypes(): Promise<any[]> {
        return (this.travelTypes = bookingService.getTravelGroupTypes());
    }

    public async loadProductTypes() {
        const [err, response] = await to(productService.getProductTypes());
        if (err) {
            return this.showError('Producttypen ophalen mislukt');
        }

        this.productTypes = response.data.filter((type) => type.id > 0);
        this.productTypes.forEach((productType) => (productType.translation = dictionaryHelper.get('ProductTypes')[productType.name]));
    }

    public async loadFinanceAccounts() {
        const [err, response] = await to(financeAccountService.getAccounts());
        if (err) {
            return this.showError('Grootboekrekeninge ophalen mislukt');
        }
        this.financeAccounts = response.data;
    }

    public async loadVATs() {
        const [err, response] = await to(productService.getVATs());
        if (err) {
            return this.showError('BTW-schalen ophalen mislukt');
        }
        this.VATs = response.data;
    }

    public async loadRelatedPriceRules(productId: number) {
        const [err, response] = await to(productService.getRelatedPriceRules(productId));
        if (err) {
            return this.showError('Gerelateerde prijsregels ophalen mislukt');
        }

        this.priceRules = response.data;
    }

    public async getProduct() {
        const newProduct = {
            discountAmount: null,
            discountPercentage: null,
            productId: null,
            productType: null,
            description: '',
            amountInStock: null,
            productTypeId: null,
            siteIds: [],
            optional: false,
        } as Product;

        newProduct.siteIds = [];

        if (this.$route.params.productId === 'nieuw') {
            this.editMode = true;
            return newProduct;
        }

        const [err, response] = await to(productService.getProduct(this.$route.params.productId));
        if (err) {
            return this.showError('Product ophalen mislukt');
        }

        const product = response.data;
        const productType = this.productTypes.find((type) => type.name === product.productType);
        if (productType) {
            this.productTypeIdObject = productType;
            product.productTypeId = productType.id;
        }
        this.selectedSites = product.siteIds;

        const financeAccount = this.financeAccounts.find((acc) => acc.financeAccountId === product.financeAccountId);
        if (financeAccount) {
            product.financeAccount = financeAccount;
        }

        const vat = this.VATs.find((vatObj) => vatObj.vatId === product.vatId);
        if (vat) {
            product.vat = vat;
        }

        return (this.product = product);
    }

    public async edit() {
        this.editMode = true;
    }

    public async cancelEdit() {
        this.editMode = false;
    }

    public getSiteName(siteId) {
        return this.sites.find((x) => x.siteId === siteId).name;
    }

    public async save() {
        let err: any;
        let response: any;

        this.product.siteIds = this.selectedSites;
        if (!this.validateObject('product') || !this.validateObject('selectedSites')) {
            return this.showValidationErrors(['Niet alle verplichte velden zijn ingevuld.']);
        }

        if ((this.product.discountAmount && this.product.discountPercentage) || (!this.product.discountAmount && !this.product.discountPercentage)) {
            return this.showValidationErrors(['Percentage en bedrag tegelijk leeg/gevuld is niet mogelijk. Kies een van beide.']);
        }

        this.showPending('Product opslaan..');

        if (this.product.productId) {
            const difference = this.oldSelectedSites.filter((x) => !this.selectedSites.includes(x));
            const rules = this.priceRules.filter((rule) => rule.siteIds.filter((id) => difference.includes(id)).length > 0);

            if (rules && rules.length > 0) {
                return this.clearAndShowError(
                    'Product wordt nog gebruikt in een businessregel binnen een park die je ontkoppeld, opslaan niet mogelijk.',
                );
            }

            [err, response] = await to(productService.updateProduct(this.product));
        } else {
            [err, response] = await to(productService.saveProduct(this.product));
        }

        if (err) {
            this.showError('Product opslaan mislukt');
        }

        this.product.productId = response.data.productId;
        this.product.siteIds = response.data.siteIds;
        this.editMode = false;
        this.clearAndShowSuccess('Product opgeslagen');

        this.$router.push({ name: 'products' });
    }

    public async selectProductType(id) {
        const selectedProduct = this.productTypes.find((productType) => {
            return productType.id === id;
        });

        this.product.productTypeId = selectedProduct.id;
        this.product.productType = selectedProduct.name;
    }

    public goToRule(rule: PriceRule) {
        $router.push({
            name: 'price-rule',
            params: { priceRuleKey: rule.priceRuleId.toString() },
        });
    }

    public getFinanceAccountName() {
        const finAcc = this.financeAccounts.find((x) => x.financeAccountId === this.product.financeAccountId);

        return finAcc ? finAcc.name : 'n.v.t';
    }

    public getVatName() {
        const vatObj = this.VATs.find((vat) => vat.vatId === this.product.vatId);

        return vatObj ? vatObj.description : 'n.v.t';
    }
}
